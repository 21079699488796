import React from 'react'

export default function() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
			<defs>
				<style>{'.edit-class{fill:none;}.edit-class-1{fill:#0e81ed;}'}</style>
			</defs>
			<rect className='edit-class' width='24' height='24' />
			<rect className='edit-class-1' width='8' height='2' transform='translate(4 9)' />
			<rect className='edit-class-1' width='6' height='2' transform='translate(4 13)' />
			<path className='edit-class-1' d='M2,2H12V6h4V9h2V4.586L13.414,0H2A2,2,0,0,0,0,2V20a2,2,0,0,0,2,2H9V20H2Z' />
			<path
				className='edit-class-1'
				d='M23.707,14.293l-3-3a1,1,0,0,0-1.414,0l-7,7a1,1,0,0,0-.263.464l-1,4a1,1,0,0,0,1.213,1.213l4-1a.991.991,0,0,0,.464-.264l7-7A1,1,0,0,0,23.707,14.293Zm-8.218,6.8-2.114.528.528-2.114,3.1-3.1L18.586,18ZM20,16.586,18.414,15,20,13.414,21.586,15Z'
			/>
		</svg>
	)
}
