import styled, { css } from 'styled-components'
import { IconCall, IconContacts, IconPrescriptions, IconResults } from '../../assets/icons'
import { Button } from '../superSetCommons'

interface Props {
	menuActive?: boolean
	width?: string
}

export const NavOption = styled.a`
	display: flex;
	flex-direction: row;
	align-items: center;

	svg {
		width: 25px;
		height: 25px;
		margin-left: 20px;
		margin-right: 10px;
	}
`

export const MenuItem = styled.span`
	color: #0b67bd;
`

export const IconMenu = styled.img`
	width: 25px;
	height: 25px;
	margin-left: 20px;
	margin-right: 10px;
`

export const ProfileIcon = styled.img`
	width: 25px;
	height: 25px;
	margin-left: 20px;
	margin-right: 10px;
`

export const ButtonMenu = styled(Button)`
	border: none;
	background: ${props => props.theme.colors.menu_button_bg};
	width: ${props => props.width || '25px'};
	height: 25px;
	padding: 0;
	margin: 0;
	border-radius: 8px;
	&:hover {
		background: ${props => props.theme.colors.menu_button_bg_hover};
	}

	svg {
		transition: transform 0.2s ease-in-out;
	}

	${props =>
		props.active
			? `background: ${props.theme.colors.font_disabled};
	svg {
		transform: rotate(-180deg);
		path {
			fill: white;
		}
	}

	&:hover {
		background: ${props.theme.colors.menu_button_bg};
	}
	`
			: ''};
	@media (max-width: 480px) {
		height: 25px;
		padding: 0;
	}
`

export const ItemMenu = styled(Button)`
	display: flex;
	align-items: center;
	height: 7vh;
	background: transparent;
	width: 100%;
	padding: 0;
	margin: 0;
	color: #5f6f81;
	font: Bold 18px/24px CentraleSans-Bold;
	text-align: left;
	@media (max-width: 768px) {
		font: Bold 14px/24px CentraleSans-Bold;
	}

	&:hover {
		background: #ececec90;
	}
`

export const dividerStyle = { maxWidth: '90%', marginLeft: '5%' }

export const Nav = styled.nav<Props>`
	position: relative;
	background-color: #fff;
	min-width: 300px;
	padding: 10px 0;
	right: ${props => (props.menuActive ? '230px' : '0')};
	top: 0px;
	bottom: 0px;
	border-radius: 10px;
	overflow: hidden;
	background: white;
	-webkit-transform: translateX(100%);
	-moz-transform: translateX(100%);
	transform: translateX(100%);
	-webkit-transition: -webkit-transform 0.3s, width 0.3s;
	-moz-transition: -moz-transform 0.3s, width 0.3s;
	transition: transform 0.3s, width 0.3s;
	${props =>
		props.menuActive &&
		css`
			-webkit-transform: translateX(0px);
			-moz-transform: translateX(0px);
			transform: translateX(0px);
			-webkit-box-shadow: -1px 3px 10px 0px rgba(190, 190, 190, 1);
			-moz-box-shadow: -1px 3px 10px 0px rgba(190, 190, 190, 1);
			box-shadow: -1px 3px 10px 0px rgba(190, 190, 190, 1);
		`}
`

export const Unordered = styled.ul`
	padding: 0 10px;
	text-align: left;
	font-size: 18px;
	&:li:not(:first-child) {
		max-width: 90%;
		box-shadow: inset 0 1px #c6d0da;
	}
`

export const SvgExamResult = styled(IconResults)`
	path {
		fill: ${props => props.theme.colors.primary};
	}
`
export const SvgProntmed = styled(IconContacts)`
	path {
		fill: ${props => props.theme.colors.primary};
	}
`

export const SvgAppointment = styled(IconCall)`
	path {
		fill: ${props => props.theme.colors.primary};
	}
`
export const SvgPrescriptions = styled(IconPrescriptions)`
	margin: 0 4px 0 -4px;
	path {
		fill: ${props => props.theme.colors.primary};
	}
`

export const ContainerMobile = styled.div`
	display: none;
	position: fixed;
	bottom: 0;
	width: 100vw;
	z-index: 2;

	@media (max-width: 480px) {
		display: block;
	}
`

export const NavContainer = styled.div``

export const ButtonMobileMenu = styled(Button)<Props>`
	@media (max-width: 480px) {
		background: ${props => props.theme.colors.menu_button_bg};
		width: ${props => props.width || '100vw'};
		height: 35px;
		padding: 5px;
		margin: 0;
		border-radius: 8px;
		color: ${props => props.theme.colors.font_dark};
		opacity: 1;

		border: ${props =>
			props.active
				? `1px solid ${props.theme.colors.secundary_border}`
				: `1px solid ${props.theme.colors.menu_button_bg_hover}`};
		&:hover {
			background: ${props => props.theme.colors.menu_button_bg_hover};
		}
	}
`
