import React from 'react'

export default function() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
			<defs>
				<style>{'.call-class{fill:none;}.call-class-1{fill:#0e81ed;}'}</style>
			</defs>
			<rect className='call-class' width='24' height='24' />
			<path className='call-class-1' d='M2,2H12V6h4V9h2V4.586L13.414,0H2A2,2,0,0,0,0,2V20a2,2,0,0,0,2,2h8V20H2Z' />
			<rect className='call-class-1' width='8' height='2' transform='translate(4 9)' />
			<rect className='call-class-1' width='5' height='2' transform='translate(4 13)' />
			<path className='call-class-1' d='M4-5426v-4H0v-2H4v-4H6v4h4v2H6v4Z' transform='translate(12 5448)' />
		</svg>
	)
}
