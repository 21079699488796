import styled from 'styled-components'
import { ModalSubtitle as Subtitle, ModalTitle as Title, ModalWrapper as Wrapper } from '../../../Modal/messageModal'
import { Button as B, InputElement as IE } from '../../../superSetCommons'
interface Props {
	error: boolean
	formIsWrong: boolean | string
	width: string | number
	margin: string | number
}

export const ModalWrapper = styled(Wrapper)``

export const ModalTitle = styled(Title)`
	text-align: left;
`

export const ModalSubtitle = styled(Subtitle)`
	text-align: left;
`

export const FormWrapper = styled.div``

export const InputElement = styled(IE)`
	width: 100%;
`

export const SubmitButton = styled(B)<Props>`
	margin: 32px 0 0 4%;
	width: 48%;
`

export const InputWrapper = styled.div<Props>`
	display: inline-block;
	width: ${props => (props.width ? props.width : '100%')};
	text-align: left;
	margin: ${props => (props.margin ? props.margin : '14px 0 0')};
`

export const DisplayFlex = styled.div`
	display: flex;
`
