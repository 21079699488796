import React from 'react'

export default function(props) {
	let color = '#748A96'
	if (typeof window !== 'undefined') {
		if (window.localStorage.getItem('marca') === 'bradesco') color = '#0b67bd'
	}
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' {...props}>
			<defs>
				<style>{`.a-logout{fill:none;}.b-logout{fill:${color};}`}</style>
			</defs>
			<rect className='a-logout' width='24' height='24' />
			<path
				className='b-logout'
				d='M18,22H7a2,2,0,0,1-2-2V17H7v3H18V4H7V7H5V4A2,2,0,0,1,7,2H18a2,2,0,0,1,2,2V20A2,2,0,0,1,18,22Z'
			/>
			<path
				className='b-logout'
				d='M11.707,7.292,10.293,8.708,12.59,11H4v2h8.582l-2.29,2.294,1.416,1.412,4.706-4.715Z'
			/>
		</svg>
	)
}
