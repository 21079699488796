import React from 'react'

export default function(props) {
	let color = '#748A96'
	if (typeof window !== 'undefined') {
		if (window.localStorage.getItem('marca') === 'bradesco') color = '#0b67bd'
	}
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' {...props}>
			<defs>
				<style>{`.a-profile{fill:none;}.b-profile{fill:${color};`}</style>
			</defs>
			<rect className='a-profile' width='24' height='24' />
			<path
				className='b-profile'
				d='M12,12.006a4,4,0,1,1,4-4A4,4,0,0,1,12,12.006Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,12,6.006Z'
			/>
			<path
				className='b-profile'
				d='M20,20.006H4v-1c0-3.533,3.29-6,8-6s8,2.467,8,6Zm-13.841-2H17.844c-.6-1.808-2.833-3-5.841-3S6.759,16.2,6.161,18.006Z'
			/>
		</svg>
	)
}
