import SEO from '@cuidardigital/commons/components/Seo/seo'
import React, { useContext, useEffect, useState } from 'react'
import Header from '../components/Header'

const TemplatePage = ({ children }: any) => {
	const [heightHeader, setHeightHeader] = useState(0)

	useEffect(() => {
		const height = document.getElementById('header')?.offsetHeight
		if (height) setHeightHeader(height)
	}, [])

	return (
		<>
			<SEO />
			<Header />
			<div style={{ paddingTop: heightHeader - 10 + 'px' }}>{children}</div>
		</>
	)
}

export default TemplatePage
