import React from 'react'
import { default as SelectReact, Props } from 'react-select'
import Async from 'react-select/async'

interface IProps extends Props {
	withBorder?: boolean
	borderBotton?: boolean
	borderColor?: string
	bg?: string
	borderTop?: boolean
	borderLeft?: boolean
	borderRight?: boolean
}

const selectStyles = {
	indicatorSeparator: (base: any) => ({
		...base,
		background: 'none'
	}),
	dropdownIndicator: (base: any) => ({
		...base,
		svg: {
			fill: '#999999'
		}
	}),
	control: (base: any) => ({
		...base,
		borderBotton: '1px',
		// borderTop: '0px',
		// borderLeft: '0px',
		// borderRight: '0px',
		borderRadius: '0px',
		backgroundColor: '#FFFFFF',
		borderColor: '#CCCCCC',
		boxShadow: 'none',
		minHeight: '48px',
		padding: '0',
		margin: '0',
		maxHeight: '48px',
		// minHeight: '57px',
		// padding: '0 16px',
		// margin: '8px 0 0',
		font: '14px/24px CentraleSansRegular',
		'&:hover': {
			borderColor: '#CCCCCC'
		}
	}),
	menu: (base: any) => ({
		...base,
		marginTop: 0,
		textAlign: 'center',
		wordWrap: 'break-word'
		// overflow: 'hidden',
	}),
	menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
	menuList: (base: any) => ({
		...base,
		padding: 0
	}),
	singleValue: (base: any) => ({
		...base,
		color: '#999999',
		fontSize: '14px',
		font: '14px/24px CentraleSansRegular',
		lineHeight: '1.2'
	}),
	placeholder: (base: any) => ({
		...base,
		color: '#999999',
		fontSize: '14px',
		font: '14px/24px CentraleSansRegular',
		lineHeight: '1.2',
		left: '0px'
	}),
	option: (base: any) => ({
		...base,
		// fontSize: '14px',
		// lineHeight: '19px',
		fontSize: '14px',
		lineHeight: '1.2',
		backgroundColor: 'transparent',
		font: '14px/24px CentraleSansRegular',
		color: '#999999',

		':active': {
			backgroundColor: '#F7F7F7',
			color: '#333333'
		},
		'&:hover': {
			backgroundColor: '#c3c3c3',
			color: '#333333'
		}
	})
}

const customControl = (props: any) => {
	if (props && !Object.values(props)) return {}
	const { bg, withBorder, borderBotton, borderTop, borderLeft, borderRight, borderColor } = props
	const border = borderColor || '#CCCCCC'
	return {
		control: (base: any) => ({
			...base,
			borderColor: border,
			borderBotton: withBorder || borderBotton ? `1px solid ${border}` : '0px',
			borderTop: borderTop ? `1px solid ${border}` : '0px',
			borderLeft: borderLeft ? `1px solid ${border}` : '0px',
			borderRight: borderRight ? `1px solid ${border}` : '0px',
			borderRadius: '0px',
			backgroundColor: bg || '#f7f7f7',
			boxShadow: 'none',
			minHeight: '48px',
			padding: '0',
			margin: '0',
			maxHeight: '48px',
			// minHeight: '57px',
			// padding: '0 16px',
			// margin: '8px 0 0',
			font: '14px/24px CentraleSansRegular',
			'&:hover': {
				borderColor: border
			}
		})
	}
}

const Select = ({ id, styles, withBorder = true, ...props }: IProps) => {
	let stls: any = { ...selectStyles }
	if (styles) stls = { ...stls, ...styles }
	stls = { ...stls, ...customControl({ ...props, withBorder }) }

	return <SelectReact styles={stls} placeholder='Selecione' menuPosition='fixed' {...props} />
}

export const SelectAsync = ({ id, styles, withBorder = true, ...props }: IProps) => {
	let stls: any = { ...selectStyles }
	if (styles) stls = { ...stls, ...styles }
	stls = { ...stls, ...customControl({ ...props, withBorder }) }

	return <Async styles={stls} placeholder='Selecione' menuPosition='fixed' {...props} />
}

export default Select
