import React from 'react'

export default function() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
			<defs>
				<style>{'.search-class{fill:none;}.search-class-1{fill:#0e81ed;}'}</style>
			</defs>
			<rect className='search-class' width='24' height='24' />
			<path className='search-class-1' d='M2,2H12V6h4V9h2V4.586L13.414,0H2A2,2,0,0,0,0,2V20a2,2,0,0,0,2,2h8V20H2Z' />
			<rect className='search-class-1' width='8' height='2' transform='translate(4 9)' />
			<rect className='search-class-1' width='5' height='2' transform='translate(4 13)' />
			<path
				className='search-class-1'
				d='M20.167,18.753a5.015,5.015,0,1,0-1.414,1.414L22,23.414,23.414,22ZM16,19a3,3,0,1,1,3-3A3,3,0,0,1,16,19Z'
			/>
		</svg>
	)
}
