import styled, { createGlobalStyle } from 'styled-components'

import CommonSelect from '@cuidardigital/commons/components/Select'

import { Span as SuperSpan } from '../superSetCommons'

interface IBanner {
	isSecretary?: boolean
}

export const SelectDoctor = styled(CommonSelect)`
	min-width: 250px;
`

export const GlobalStyle = createGlobalStyle`
	body {
		margin: 0;
	}
`

export const HeaderBanner = styled.div<IBanner>`
	width: 100%;
	max-height: 110px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 20px #0000001a;
	display: flex;
	justify-content: space-between;
	padding: 16px 32px 10px;
	align-items: center;
	position: fixed;
	z-index: 5;
	top: 0;

	@media (max-width: 768px) {
		padding-bottom: 0;
	}
	@media (max-width: 480px) {
		padding: 0 10px;
	}
`

export const InfoUser = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

export const Logo = styled.img`
	max-width: 20%;
	cursor: pointer;
	@media (max-width: 480px) {
		height: 56px;
		object-fit: cover;
		max-width: 14vw;
		overflow: hidden;
		object-position: 0;
	}
`

export const ProfileBlue = styled.div`
	height: 35px;
	width: 35px;
	background: ${props => props.theme.colors.primary};
	border-radius: 50%;
`

export const Profile = styled.img`
	height: 40px;
`

export const ProfileText = styled(SuperSpan)`
	display: block;
	text-align: right;
	letter-spacing: 0;
	color: ${props => props.theme.colors.font_dark};
	margin-left: 15px;
`

export const ContainerAssistant = styled.div`
	margin-top: -20px;
`

export const styles = {
	control: (base: any) => ({
		...base,
		borderBotton: '1px',
		borderRadius: '1px',
		borderColor: '#CCCCCC',
		boxShadow: 'none',
		padding: '0',
		margin: '0',
		marginLeft: '10px',
		fontFamily: 'CentraleSansRegular',
		'&:hover': {
			borderColor: '#CCCCCC'
		}
	}),
	option: (base: any) => ({
		...base,
		fontSize: '14px',
		lineHeight: '1.1',
		backgroundColor: 'transparent',
		fontFamily: 'CentraleSansRegular',
		color: '#999999',
		':active': {
			backgroundColor: '#FFFFFF',
			color: '#333333'
		},
		'&:hover': {
			backgroundColor: '#c3c3c3',
			color: '#333333'
		}
	})
}
