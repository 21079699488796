import React from 'react'

export default function() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24.001' height='24.001' viewBox='0 0 24.001 24.001'>
			<defs>
				<style>{'.icon-prescriptions{fill:none;}.icon-prescriptions-1{fill:#0e81ed;}'}</style>
			</defs>
			<g transform='translate(0.001)'>
				<rect className='icon-prescriptions' width='24.001' height='24.001' transform='translate(-0.001)' />
				<path
					className='icon-prescriptions-1'
					d='M19.707,6.293l-4-4A1,1,0,0,0,15,2H5A1,1,0,0,0,4,3V21a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V7A1,1,0,0,0,19.707,6.293ZM18,20H6V4h8.586L18,7.415Z'
				/>
				<rect className='icon-prescriptions-1' width='8' height='2' transform='translate(8 17)' />
				<rect className='icon-prescriptions-1' width='8' height='2' transform='translate(8 14)' />
				<path className='icon-prescriptions-1' d='M13,6H11V8H9v2h2v2h2V10h2V8H13Z' />
			</g>
		</svg>
	)
}
