import { navigate } from 'gatsby'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import { logout } from '@cuidardigital/commons/services/auth'

import FinancialIcon from '@cuidardigital/commons/assets/icons/financial'
import ExitIcon from '@cuidardigital/commons/assets/icons/logout'
import ProfileIcon from '@cuidardigital/commons/assets/icons/profile'
import SecretaryIcon from '@cuidardigital/commons/assets/icons/secretary'

import './component.css'

import { IconArrow } from '@cuidardigital/commons/assets/icons'
import { TOUR_STEP_ID } from '@cuidardigital/commons/configs/tour'
import { mobilecheck } from '@cuidardigital/commons/utils/isMobile'
import { Divider } from 'semantic-ui-react'
import { store } from '../../stores/app'
import AssitantProfile from '../Profile/Assistant/common'
import { ASSISTANT, FINANCIAL, FINANCIAL_REGISTER } from '../../routes'
import useCustomState from '../../hooks/useState'
import {
	ButtonMenu,
	dividerStyle,
	MenuItem,
	Nav,
	NavContainer,
	NavOption,
	Unordered
} from './styles'

interface MenuProps {
	children?: any
	isDoctor: boolean
}
let noCloseClickOut = false

const Menu = ({ isDoctor }: MenuProps) => {
	// @ts-ignore
	const { state: stateContext } = useContext(store)

	const [menuActive, setMenuActive] = useState(false)

	const [state, setState] = useCustomState({ openProfile: false })

	const eventtype = mobilecheck() ? 'touchstart' : 'click'

	// ABERTURA DO MENU A PARTIR DE QUALQUER LUGAR VIA CÓDIGO
	useEffect(() => {
		window.openMenu = (active: boolean, noClose: boolean) => {
			setMenuActive(active)
			if (noClose !== undefined) noCloseClickOut = noClose
		}
	}, [])

	useEffect(() => {
		if (eventtype === 'click') if (menuActive) document.addEventListener(eventtype, bodyClickFn)
		window.isOpenMenu = menuActive
	}, [menuActive])

	const bodyClickFn = () => {
		// REMOVER LISTENER PARA FECHAR O MENU AO CLICAR FORA
		if (noCloseClickOut) return
		setMenuActive(false)
		document.removeEventListener(eventtype, bodyClickFn)
	}

	const handleClick = () => {
		setMenuActive(!menuActive)
	}

	const handleProfile = () => setState({ openProfile: true })

	const handleCloseModal = () => setState({ openProfile: false })

	const handleSecretary = useCallback(() => {
		navigate(ASSISTANT)
	}, [])

	const handleFinancial = useCallback(() => {
		// navigate(FINANCIAL)
		navigate(FINANCIAL_REGISTER)
	}, [])

	return (
		<>
			<ul id='gn-menu' className='gn-menu-main'>
				<li className='gn-trigger'>
					<span className={menuActive ? 'gn-selected' : ''} onClick={handleClick}>
						<ButtonMenu
							id='button-menu'
							className={TOUR_STEP_ID.OPEN_MENU}
							active={menuActive}
							bg='GRAY'
						>
							<IconArrow />
						</ButtonMenu>
					</span>
					<Nav menuActive={menuActive}>
						<NavContainer>
							<Unordered>
								{!isDoctor && (
									<li data-testid='notDoctorProfile' className={TOUR_STEP_ID.MENU_PROFILE}>
										<NavOption id='button-menu-perfil' onClick={handleProfile}>
											<ProfileIcon />
											<MenuItem>Perfil</MenuItem>
										</NavOption>
									</li>
								)}
								{isDoctor && (
									<>
										{/* <li data-testid='doctorProfile' className={TOUR_STEP_ID.MENU_CERTIFICATE}>
											<NavOption id='button-menu-certificate' onClick={handleSecretary}>
												<IconMenu src={secretariatIcon} />
												<MenuItem>Adquirir Certificado</MenuItem>
											</NavOption>
										</li> */}
										<li data-testid='doctorProfile' className={TOUR_STEP_ID.MENU_SECRETARY}>
											<NavOption id='button-menu-secretary' onClick={handleSecretary}>
												<SecretaryIcon data-testid='doctorProfileIcon' />
												<MenuItem>Secretariado</MenuItem>
											</NavOption>
										</li>
									</>
								)}
								<Divider style={dividerStyle} />
								{/* <li className={TOUR_STEP_ID.MENU_QUESTIONS}> */}
								{/* <li className={TOUR_STEP_ID.MENU_FINANCIAL}>
									<NavOption id='button-menu-financial' onClick={handleFinancial}>
										<FinancialIcon />
										<MenuItem>Financeiro</MenuItem>
									</NavOption>
								</li>
								<Divider style={dividerStyle} /> */}
								<li>
									<NavOption id='button-menu-exit' onClick={logout}>
										<ExitIcon />
										<MenuItem>Sair</MenuItem>
									</NavOption>
								</li>
							</Unordered>
						</NavContainer>
					</Nav>
				</li>
			</ul>
			{state.openProfile && (
				<AssitantProfile handleClose={handleCloseModal} user={stateContext.session} />
			)}
		</>
	)
}
export default Menu
