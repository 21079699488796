import { navigate } from 'gatsby'
import React, { useCallback, useContext, useMemo, useState } from 'react'

import IDoctor from '@cuidardigital/commons/types/IDoctor'
import { HOME } from '../../routes'
import { store, TYPES } from '../../stores/app'
import Menu from '../Menu'

import profile from '@cuidardigital/commons/assets/img/profile.png'

import {
	ContainerAssistant,
	GlobalStyle,
	HeaderBanner,
	InfoUser,
	Logo,
	Profile,
	ProfileBlue,
	ProfileText,
	SelectDoctor,
	styles
} from './styles'

import { graphql, useStaticQuery } from 'gatsby'
import logo from '../../assets/img/logo.png'

interface IValue {
	value: string
	label: string
}

const Header = () => {
	const { state, dispatch } = useContext(store)

	const { session, doctorCurrent, doctorList } = state
	const { name, isDoctor } = session
	const { name: doctorName, cpf: doctorCpf } = doctorCurrent

	const doctorListOptions = useMemo(
		() => doctorList.map((item: IDoctor) => ({ value: item.cpf, label: `Dr(a). ${item.name}` })),
		[doctorList]
	)

	const [selected, setSelected] = useState<IValue>({ value: doctorCpf, label: `Dr(a). ${doctorName}` })

	const data = useStaticQuery(query)
	const { image } = data.saudeDigital.metadata

	const handleSelect = useCallback(
		(value: any) => {
			if (value.value !== doctorCurrent.cpf) {
				// @ts-ignore
				dispatch({
					type: TYPES.SET_DOCTOR_CURRENT,
					payload: doctorList.find((item: IDoctor) => item.cpf === value.value)
				})
				setSelected(value)
			}
		},
		[doctorCurrent, doctorList]
	)

	const handleGoHome = () => {
		navigate(HOME)
	}

	return (
		<>
			<GlobalStyle />
			<HeaderBanner id='header' isSecretary={!state.session.isDoctor}>
				<Logo onClick={handleGoHome} src={image.src} alt='Saúde Digita' />
				<InfoUser>
					{isDoctor ? (
						<>
							<Profile src={profile} />
							<ProfileText bold fontSize='16px/24px'>
								Dr(a). {name}
							</ProfileText>
						</>
					) : (
						<>
							<ProfileBlue />
							<ContainerAssistant>
								<ProfileText bold fontSize='16px/24px'>
									{name}
								</ProfileText>
								<SelectDoctor
									id='select-doctors'
									options={doctorListOptions}
									placeholder='Selecione'
									value={selected}
									onChange={handleSelect}
									maxMenuHeight={200}
									styles={styles}
									inputId='select-doctors'
								/>
							</ContainerAssistant>
						</>
					)}

					<div style={{ marginTop: 8 }}>
						<Menu isDoctor={isDoctor} />
					</div>
				</InfoUser>
			</HeaderBanner>
		</>
	)
}

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "bradesco" }) {
			metadata {
				image: logo_bradesco {
					src: imgix_url
				}
			}
		}
	}
`

export default Header
