import React from 'react'

export default function(props) {
	let color = '#748A96'
	if (typeof window !== 'undefined') {
		if (window.localStorage.getItem('marca') === 'bradesco') color = '#0b67bd'
	}
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' {...props}>
			<defs>
				<style>{`.a-secretary{fill:none;}.b-secretary{fill:${color};}`}</style>
			</defs>
			<rect className='a-secretary' width='24' height='24' />
			<path className='b-secretary' d='M9,19a2.793,2.793,0,0,1,3-3h0a2.793,2.793,0,0,1,3,3Z' />
			<circle className='b-secretary' cx='2' cy='2' r='2' transform='translate(10.001 11)' />
			<path
				className='b-secretary'
				d='M18,22H6a1,1,0,0,1-1-1V14H4a2,2,0,0,1-1.414-3.414l8-7.981A1.986,1.986,0,0,1,12,2.02h0a1.986,1.986,0,0,1,1.414.586l8,7.979A2,2,0,0,1,20,14H19v7A1,1,0,0,1,18,22ZM7,20H17V13a1,1,0,0,1,1-1h2L12,4.02,4,12H6a1,1,0,0,1,1,1Z'
			/>
		</svg>
	)
}
