export const TOUR_STEP_ID = {
	CONSULTATION_OPENED: 'tour-consultation-opened',
	BUTTON_NEW_CONSULTATION: 'tour-button-newconsultation',
	OPEN_MENU: 'tour-open-menu',
	MENU_SECRETARY: 'tour-menu-secretary',
	MENU_QUESTIONS: 'tour-menu-questions',
	MENU_FINANCIAL: 'tour-menu-financial',
	MENU_CERTIFICATE: 'tour-menu-certificate',
	MENU_PROFILE: 'tour-menu-profile',
	CONSULTATION_CLOSED: 'tour-consultation-closed',
	OPEN_SUPPORT: 'tour-support-open',
	SUPPORT_TECH: 'tour-support-tech',
	SUPPORT_ADVISORY: 'tour-support-advisory',
	SUPPORT_CARD: 'tour-support-card',
	OPEN_PATIENT_RESULTS: 'tour-open-patient_results',
	PATIENT_BUTTON_PRONTMED: 'tour-patient-button-prontmed',
	PATIENT_BUTTON_EXAMS: 'tour-patient-button-exams',
	PATIENT_BUTTON_PRESCRIPTIONS: 'tour-patient-button-prescriptions',
	COMPLETED: 'tour-completed',
	SECRETARY_SELECT_DOCTOR: 'tour-secretary-select-doctor'
}
export const tourSteps = (marca: string) => (data: any, isDoctor: boolean) => {
	const cosmicMarca = marca === 'bradesco' ? 'saudeDigital' : 'cuidarDigital'
	const labelTotal = marca === 'bradesco' ? 'trutru' : 'trutru'
	let steps = [
		{
			id: TOUR_STEP_ID.CONSULTATION_OPENED,
			title: `<h1>${data[cosmicMarca].metadata.tour_platform.schedules.title}</h1>`,
			text: data[cosmicMarca].metadata.tour_platform.schedules.paragraph,
			attachTo: { element: `.${TOUR_STEP_ID.CONSULTATION_OPENED}`, on: 'right' },
			classes: 'tour-container',
			buttons: [
				{ text: `Passo 1 ${labelTotal}`, classes: 'tour-label-step-number' },
				{
					type: 'next',
					classes: 'tour-button-primary tour-consultation-next',
					text: data[cosmicMarca].metadata.tour_platform.button_next
				}
			],
			bradesco: true,
			secretary: true,
			doctor: true
		},
		{
			id: TOUR_STEP_ID.BUTTON_NEW_CONSULTATION,
			title: `<h1>${data[cosmicMarca].metadata.tour_platform.new_appointment.title}</h1>`,
			text: data[cosmicMarca].metadata.tour_platform.new_appointment.paragraph,
			attachTo: { element: `.${TOUR_STEP_ID.BUTTON_NEW_CONSULTATION}`, on: 'left' },
			classes: 'tour-container',
			buttons: [
				{ text: `Passo 2 ${labelTotal}`, classes: 'tour-label-step-number' },
				{
					type: 'back',
					classes: 'tour-button-secundary tour-new-consultation-back',
					text: data[cosmicMarca].metadata.tour_platform.button_back
				},
				{
					type: 'next',
					classes: 'tour-button-primary tour-new-consultation-next',
					text: data[cosmicMarca].metadata.tour_platform.button_next
				}
			],
			bradesco: true,
			secretary: true,
			doctor: true
		},
		{
			id: TOUR_STEP_ID.SECRETARY_SELECT_DOCTOR,
			title: `<h1>${data[cosmicMarca].metadata.tour_platform.secretaryDoctorList.title}</h1>`,
			text: data[cosmicMarca].metadata.tour_platform.secretaryDoctorList.paragraph,
			attachTo: { element: `.${TOUR_STEP_ID.SECRETARY_SELECT_DOCTOR}`, on: 'left' },
			classes: 'tour-container',
			buttons: [
				{ text: `Passo 2 ${labelTotal}`, classes: 'tour-label-step-number' },
				{
					type: 'back',
					classes: 'tour-button-secundary tour-new-consultation-back',
					text: data[cosmicMarca].metadata.tour_platform.button_back
				},
				{
					type: 'next',
					classes: 'tour-button-primary tour-new-consultation-next',
					text: data[cosmicMarca].metadata.tour_platform.button_next
				}
			],
			bradesco: true,
			secretary: true,
			doctor: false
		},
		{
			id: TOUR_STEP_ID.OPEN_MENU,
			title: 'Menu de opções',
			attachTo: { element: `.${TOUR_STEP_ID.OPEN_MENU}`, on: 'left' },
			bradesco: true,
			secretary: true,
			doctor: true
		},
		// {
		// 	id: TOUR_STEP_ID.MENU_CERTIFICATE,
		// 	title: `<h1>${data[cosmicMarca].metadata.tour_platform.acquire_certificate.title}</h1>`,
		// 	text: data[cosmicMarca].metadata.tour_platform.acquire_certificate.paragraph,
		// 	attachTo: { element: `.${TOUR_STEP_ID.MENU_CERTIFICATE}`, on: 'left' },
		// classes: 'tour-container',
		// 	buttons: [
		// 		{
		// 			type: 'back',
		// 			classes: 'tour-button-secundary',
		// 			text: data[cosmicMarca].metadata.tour_platform.button_back
		// 		},
		// 		{
		// 			type: 'next',
		// classes: 'tour-button-primary',
		// 			text: data[cosmicMarca].metadata.tour_platform.button_next
		// 		}
		// 	]
		// },
		{
			id: TOUR_STEP_ID.MENU_PROFILE,
			title: `<h1>${data[cosmicMarca].metadata.tour_platform.menuProfile.title}</h1>`,
			text: data[cosmicMarca].metadata.tour_platform.menuProfile.paragraph,
			attachTo: { element: `.${TOUR_STEP_ID.MENU_PROFILE}`, on: 'left' },
			classes: 'tour-container',
			buttons: [
				{ text: `Passo 4 ${labelTotal}`, classes: 'tour-label-step-number' },
				{
					type: 'back',
					classes: 'tour-button-secundary tour-menu-profile-back',
					text: data[cosmicMarca].metadata.tour_platform.button_back
				},
				{
					type: 'next',
					classes: 'tour-button-primary tour-menu-profile-next',
					text: data[cosmicMarca].metadata.tour_platform.button_next
				}
			],
			bradesco: true,
			secretary: true,
			doctor: false
		},
		{
			id: TOUR_STEP_ID.MENU_SECRETARY,
			title: `<h1>${data[cosmicMarca].metadata.tour_platform.secretary.title}</h1>`,
			text: data[cosmicMarca].metadata.tour_platform.secretary.paragraph,
			attachTo: { element: `.${TOUR_STEP_ID.MENU_SECRETARY}`, on: 'left' },
			classes: 'tour-container',
			buttons: [
				{ text: `Passo 3 ${labelTotal}`, classes: 'tour-label-step-number' },
				{
					type: 'back',
					classes: 'tour-button-secundary tour-menu-secretary-back',
					text: data[cosmicMarca].metadata.tour_platform.button_back
				},
				{
					type: 'next',
					classes: 'tour-button-primary tour-menu-secretary-next',
					text: data[cosmicMarca].metadata.tour_platform.button_next
				}
			],
			bradesco: true,
			secretary: false,
			doctor: true
		},
		{
			id: TOUR_STEP_ID.MENU_QUESTIONS,
			title: `<h1>${data[cosmicMarca].metadata.tour_platform.doubts.title}</h1>`,
			text: data[cosmicMarca].metadata.tour_platform.doubts.paragraph,
			attachTo: { element: `.${TOUR_STEP_ID.MENU_QUESTIONS}`, on: 'left' },
			classes: 'tour-container',
			buttons: [
				{ text: `Passo 4 ${labelTotal}`, classes: 'tour-label-step-number' },
				{
					type: 'back',
					classes: 'tour-button-secundary tour-menu-questions-back',
					text: data[cosmicMarca].metadata.tour_platform.button_back
				},
				{
					type: 'next',
					classes: 'tour-button-primary tour-menu-questions-next',
					text: data[cosmicMarca].metadata.tour_platform.button_next
				}
			],
			bradesco: false,
			secretary: true,
			doctor: true
		},
		{
			id: TOUR_STEP_ID.MENU_FINANCIAL,
			title: `<h1>${data[cosmicMarca].metadata.tour_platform.financial.title}</h1>`,
			text: data[cosmicMarca].metadata.tour_platform.financial.paragraph,
			attachTo: { element: `.${TOUR_STEP_ID.MENU_FINANCIAL}`, on: 'left' },
			classes: 'tour-container',
			buttons: [
				{ text: `Passo 4 ${labelTotal}`, classes: 'tour-label-step-number' },
				{
					type: 'back',
					classes: 'tour-button-secundary tour-menu-questions-back',
					text: data[cosmicMarca].metadata.tour_platform.button_back
				},
				{
					type: 'next',
					classes: 'tour-button-primary tour-menu-questions-next',
					text: data[cosmicMarca].metadata.tour_platform.button_next
				}
			],
			// TODO feature incompleta
			bradesco: false,
			secretary: false,
			doctor: false
		},
		{
			id: TOUR_STEP_ID.CONSULTATION_CLOSED,
			title: `<h1>${data[cosmicMarca].metadata.tour_platform.consultations.title}</h1>`,
			text: data[cosmicMarca].metadata.tour_platform.consultations.paragraph,
			attachTo: { element: `.${TOUR_STEP_ID.CONSULTATION_CLOSED}`, on: 'right' },
			classes: 'tour-container',
			buttons: [
				{ text: `Passo 5 ${labelTotal}`, classes: 'tour-label-step-number' },
				{
					type: 'back',
					classes: 'tour-button-secundary tour-consultation-closed-back',
					text: data[cosmicMarca].metadata.tour_platform.button_back
				},
				{
					type: 'next',
					classes: 'tour-button-primary tour-consultation-closed-next',
					text: data[cosmicMarca].metadata.tour_platform.button_next
				}
			],
			bradesco: true,
			secretary: true,
			doctor: true
		},
		{
			id: TOUR_STEP_ID.OPEN_SUPPORT,
			title: 'Dicas rápidas',
			attachTo: { element: `.${TOUR_STEP_ID.OPEN_SUPPORT}`, on: 'left' },
			bradesco: true,
			secretary: true,
			doctor: true
		},
		{
			id: TOUR_STEP_ID.SUPPORT_TECH,
			title: `<h1>${data[cosmicMarca].metadata.tour_platform.support.title}</h1>`,
			text: data[cosmicMarca].metadata.tour_platform.support.paragraph,
			attachTo: { element: `.${TOUR_STEP_ID.SUPPORT_TECH}`, on: 'left' },
			classes: 'tour-container',
			buttons: [
				{ text: `Passo 6 ${labelTotal}`, classes: 'tour-label-step-number' },
				{
					type: 'back',
					classes: 'tour-button-secundary tour-consultation-closed-back',
					text: data[cosmicMarca].metadata.tour_platform.button_back
				},
				{
					type: 'next',
					classes: 'tour-button-primary tour-consultation-closed-next',
					text: data[cosmicMarca].metadata.tour_platform.button_next
				}
			],
			bradesco: true,
			secretary: true,
			doctor: true
		},
		{
			id: TOUR_STEP_ID.SUPPORT_ADVISORY,
			title: `<h1>${data[cosmicMarca].metadata.tour_platform.advisoryDadone.title}</h1>`,
			text: data[cosmicMarca].metadata.tour_platform.advisoryDadone.paragraph,
			attachTo: { element: `.${TOUR_STEP_ID.SUPPORT_ADVISORY}`, on: 'right' },
			classes: 'tour-container',
			buttons: [
				{ text: `Passo 7 ${labelTotal}`, classes: 'tour-label-step-number' },
				{
					type: 'back',
					classes: 'tour-button-secundary tour-consultation-closed-back',
					text: data[cosmicMarca].metadata.tour_platform.button_back
				},
				{
					type: 'next',
					classes: 'tour-button-primary tour-consultation-closed-next',
					text: data[cosmicMarca].metadata.tour_platform.button_next
				}
			],
			bradesco: false,
			secretary: true,
			doctor: true
		},
		{
			id: TOUR_STEP_ID.SUPPORT_CARD,
			title: `<h1>${data[cosmicMarca].metadata.tour_platform.advisoryCard.title}</h1>`,
			text: data[cosmicMarca].metadata.tour_platform.advisoryCard.paragraph,
			attachTo: { element: `.${TOUR_STEP_ID.SUPPORT_CARD}`, on: 'left' },
			classes: 'tour-container',
			buttons: [
				{ text: `Passo 5 ${labelTotal}`, classes: 'tour-label-step-number' },
				{
					type: 'back',
					classes: 'tour-button-secundary tour-consultation-closed-back',
					text: data[cosmicMarca].metadata.tour_platform.button_back
				},
				{
					type: 'next',
					classes: 'tour-button-primary tour-consultation-closed-next',
					text: data[cosmicMarca].metadata.tour_platform.button_next
				}
			],
			bradesco: false,
			// TODO faltando textos para liberar para prod
			secretary: false,
			doctor: false
		},
		{
			id: TOUR_STEP_ID.OPEN_PATIENT_RESULTS,
			title: '',
			text: data[cosmicMarca].metadata.tour_platform.patient_description,
			bradesco: true,
			secretary: false,
			doctor: true
		},
		{
			id: TOUR_STEP_ID.PATIENT_BUTTON_PRONTMED,
			title: `<h1>${data[cosmicMarca].metadata.tour_platform.medicalRecord.title}</h1>`,
			text: data[cosmicMarca].metadata.tour_platform.medicalRecord.paragraph,
			attachTo: { element: `.${TOUR_STEP_ID.PATIENT_BUTTON_PRONTMED}`, on: 'right' },
			classes: 'tour-container',
			buttons: [
				{ text: `Passo 6 ${labelTotal}`, classes: 'tour-label-step-number' },
				{
					type: 'back',
					classes: 'tour-button-secundary tour-prontmed-back',
					text: data[cosmicMarca].metadata.tour_platform.button_back
				},
				{
					type: 'next',
					classes: 'tour-button-primary tour-prontmed-next',
					text: data[cosmicMarca].metadata.tour_platform.button_next
				}
			],
			bradesco: true,
			secretary: false,
			doctor: true
		},
		{
			id: TOUR_STEP_ID.PATIENT_BUTTON_EXAMS,
			title: `<h1>${data[cosmicMarca].metadata.tour_platform.exams_results.title}</h1>`,
			text: data[cosmicMarca].metadata.tour_platform.exams_results.paragraph,
			attachTo: { element: `.${TOUR_STEP_ID.PATIENT_BUTTON_EXAMS}`, on: 'right' },
			classes: 'tour-container',
			buttons: [
				{ text: `Passo 7 ${labelTotal}`, classes: 'tour-label-step-number' },
				{
					type: 'back',
					classes: 'tour-button-secundary tour-exams-back',
					text: data[cosmicMarca].metadata.tour_platform.button_back
				},
				{
					type: 'next',
					classes: 'tour-button-primary tour-exams-next',
					text: data[cosmicMarca].metadata.tour_platform.button_next
				}
			],
			bradesco: true,
			secretary: false,
			doctor: true
		},
		{
			id: TOUR_STEP_ID.PATIENT_BUTTON_PRESCRIPTIONS,
			title: `<h1>${data[cosmicMarca].metadata.tour_platform.prescriptions.title}</h1>`,
			text: data[cosmicMarca].metadata.tour_platform.prescriptions.paragraph,
			attachTo: { element: `.${TOUR_STEP_ID.PATIENT_BUTTON_PRESCRIPTIONS}`, on: 'right' },
			classes: 'tour-container',
			buttons: [
				{ text: `Passo 8 ${labelTotal}`, classes: 'tour-label-step-number' },
				{
					type: 'back',
					classes: 'tour-button-secundary tour-prescriptions-back',
					text: data[cosmicMarca].metadata.tour_platform.button_back
				},
				{
					type: 'next',
					classes: 'tour-button-primary tour-prescriptions-next',
					text: data[cosmicMarca].metadata.tour_platform.button_next
				}
			],
			bradesco: true,
			secretary: false,
			doctor: true
		},
		{
			id: TOUR_STEP_ID.COMPLETED,
			title: `<h1 style="margin: auto;">
			${data[cosmicMarca].metadata.tour_platform.tour_complete.title}
			</h1>`,
			text: data[cosmicMarca].metadata.tour_platform.tour_complete.paragraph,
			classes: 'tour-container center',
			buttons: [
				// { text: `Passo 9 ${labelTotal}`, classes: 'tour-label-step-number' },
				// {
				// 	type: 'back',
				// 	classes: 'tour-button-secundary',
				// 	text: data[cosmicMarca].metadata.tour_platform.button_back
				// },
				{
					type: 'next',
					classes: 'tour-button-primary tour-button-final tour-completed-next',
					text: data[cosmicMarca].metadata.tour_platform.tour_complete.tour_complete_button_start
				}
			],
			bradesco: true,
			secretary: true,
			doctor: true
		}
	]

	if (marca === 'bradesco') {
		steps = steps.filter(item => item.bradesco)
	}

	const result = steps.filter(item => (isDoctor ? item.doctor : item.secretary))
	let stepNumber = 1
	const stepsWithoutSpan = isDoctor ? 4 : 3
	return result.map(item => ({
		...item,
		buttons: (item.buttons || []).map(button => {
			if (button.classes === 'tour-label-step-number') {
				const text = `Passo ${stepNumber} de ${result.length - stepsWithoutSpan}`
				stepNumber++
				return {
					...button,
					text
				}
			}
			return button
		})
	}))
}

export const tourOptions = {
	defaultStepOptions: {
		cancelIcon: {
			enabled: true
		}
	},
	useModalOverlay: true,
	classPrefix: 'tour-'
}
