// @ts-ignore
import React, { useContext, useEffect, useState } from 'react'

import __filter__ from 'lodash/filter'
import __keys__ from 'lodash/keys'
import __values__ from 'lodash/values'

import ShowModal from '@cuidardigital/commons/components/Modal'

import {
	getSecretaryInfo,
	updateSecretary
} from '@cuidardigital/commons/services/secretary.services'

import { isoStringToDateString, validateDateBirthday } from '@cuidardigital/commons/utils/date'

import { Loader } from '@cuidardigital/commons/components/Loader'

import { formatDate, formatPhone, validateEmail } from '@cuidardigital/commons/utils/masks'
import { ButtonReturn, InputError, InputLabel } from '../../../superSetCommons'
import useCustomState from '../../../../hooks/useState'
import MessageModal from '../../../Modal'
import { store, TYPES } from '../../../../stores/app'
import {
	DisplayFlex,
	FormWrapper,
	InputElement,
	InputWrapper,
	ModalSubtitle,
	ModalTitle,
	ModalWrapper,
	SubmitButton
} from './styles'

// const override = css`
// 	display: block;
// 	margin: 0 auto;
// 	color: red;
// `

interface IHandleChange {
	name: string
	value: string | number
}

interface ObjectLiteral {
	[key: string]: any
}

interface Props {
	user?: IUser
	handleClose: () => void
}

interface IUser {
	name: string
	cpf: string
	email: string
	birthDay?: string
	mobile?: string
}

interface IErrorInput {
	name_error?: boolean
	password_error?: boolean
	email_error?: boolean
	[key: string]: any
}

interface IUser {
	name: string
	cpf: string
	email: string
	birthDay?: string
	mobile?: string
}

const limits: ObjectLiteral = {
	name: { min: 7, type: 'name' },
	email: { min: 7, type: 'email' },
	birthDay: { min: 9, max: 10, type: 'birthDay' },
	phone: { min: 10, max: 11, type: 'phone' }
}

const initialState = {
	name: '',
	cpf: '',
	email: '',
	birthDay: '',
	mobile: '',
	error: {},
	genre: '',
	response: '',
	loading: '',
	disabled: true
}

const ProfileForm: React.FC<Props> = ({ user, handleClose }) => {
	const { state: storeState, dispatch } = useContext(store)
	const [state, setState] = useCustomState(initialState)
	const [showMessage, setShowMessage] = useState(false)
	const [messageToShow, setMessageToShow] = useState<any>()

	const {
		name,
		email,
		birthDay,
		error: {},
		cpf,
		phone,
		loading,
		disabled,
		optins
	} = state

	useEffect(() => {
		normalizerComponent()
		fetchAssistantInfo()
	}, [])

	const fetchAssistantInfo = () => {
		if (user && user.cpf) {
			// @ts-ignore
			getSecretaryInfo({ secretaryCpf: user.cpf })
				.then((response: ObjectLiteral) => {
					if (response) {
						if (response.status === 200) {
							const data = response.data[0]
							setState({
								...data,
								birthDay: isoStringToDateString(data.birthDay),
								optins: data.optins.map(opt => opt.value)
							})
						} else {
							setMessageToShow({
								title: 'Pedimos desculpa',
								message: response.data.message,
								buttonText: 'Ok, entendi'
							})
							setShowMessage(true)
						}
					} else {
						setMessageToShow({
							title: 'Pedimos desculpa',
							message: JSON.stringify(response),
							buttonText: 'Ok, entendi'
						})
						setShowMessage(true)
					}
				})
				.catch(e => {
					setMessageToShow({
						title: 'Pedimos desculpa',
						message: JSON.stringify(e),
						buttonText: 'Ok, entendi'
					})
					setShowMessage(true)
				})
		}
	}

	const handleDate = (e: any) => {
		const fDate = formatDate(event.target.value)
		const error = {
			...state.error,
			birthDay_error: !validateDateBirthday(fDate)
		}

		setState({ error, birthDay: fDate })
		validateForm(error)
	}

	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		{ name, value }: IHandleChange
	) => {
		event.preventDefault()

		const nextState = { ...state, [name]: value }
		const error = {
			...state.error,
			[`${name}_error`]:
				name === 'email' ? validateEmail(value) : nextState[name].length < limits[name].min
		}

		setState({ error, [name]: value })

		validateForm(error)
	}

	const handleSubmit = async () => {
		setState({ loading: true })

		const payload = {
			name,
			phone,
			email,
			cpf,
			birthDay,
			optins
		}

		updateSecretary(payload)
			.then(response => {
				if (response) {
					if (response.status === 200 || response.status === 204) {
						setMessageToShow({
							title: 'Atualização de perfil',
							message: 'Dados atualizados com sucesso',
							buttonText: 'Ok, entendi'
						})
						dispatch({ type: TYPES.SET_USER_SESSION, payload: { ...storeState.session, name } })
					} else {
						setMessageToShow({
							title: 'Pedimos desculpa',
							message: response.data.message,
							buttonText: 'Ok, entendi'
						})
						setShowMessage(true)
					}
				} else {
					setMessageToShow({
						title: 'Pedimos desculpa',
						message: JSON.stringify(response),
						buttonText: 'Ok, entendi'
					})
					setShowMessage(true)
				}
			})
			.catch(e => {
				setMessageToShow({
					title: 'Pedimos desculpa',
					message: JSON.stringify(e),
					buttonText: 'Ok, entendi'
				})
				setShowMessage(true)
			})
			.finally(() => {
				setShowMessage(true)
				setState({ loading: false, disabled: true })
			})
	}

	const normalizerComponent = () => setState({ ...user })

	const onCloseModal = () => {
		setState({ loading: false })
		setShowMessage(false)
		handleClose()
	}

	const validateForm = (error: IErrorInput) => {
		if (__keys__(error).length >= 1) {
			setState({ disabled: __filter__(error, field => field).length > 0 })
		}
	}

	return (
		<>
			{state.loading && <Loader />}
			{showMessage && (
				<MessageModal
					onClose={onCloseModal}
					title={messageToShow?.title}
					message={messageToShow?.message}
					buttonText={messageToShow?.buttonText}
				/>
			)}
			<ShowModal onClose={handleClose}>
				<ModalWrapper>
					<ModalTitle wrap bold fontSize='32px/40px'>
						Perfil
					</ModalTitle>
					<ModalSubtitle wrap fontSize='16px/24px'>
						Edite seus dados alterando os campos abaixo
					</ModalSubtitle>
					<FormWrapper>
						<InputWrapper>
							<InputLabel fontSize='14px/24px'>Nome</InputLabel>
							<InputElement
								p='2px 10px'
								withAllBorder
								id='new-assistant-name'
								placeholder='Nome completo'
								value={state.name}
								// tslint:disable-next-line: jsx-no-lambda
								onChange={event =>
									handleChange(event, {
										name: 'name',
										value: event.target.value
									})
								}
								error={state.error.name_error}
							/>
							{state.error.name_error && (
								<InputError fontSize='12px/18px'>Nome é obrigatório</InputError>
							)}
						</InputWrapper>
						<DisplayFlex>
							<InputWrapper width='calc(50% - 12px)'>
								<InputLabel fontSize='14px/24px'>E-mail</InputLabel>
								<InputElement
									p='2px 10px'
									withAllBorder
									id='new-assistant-email'
									placeholder='paciente@email.com.br'
									value={state.email}
									// tslint:disable-next-line: jsx-no-lambda
									onChange={event =>
										handleChange(event, {
											name: 'email',
											value: event.target.value
										})
									}
									error={state.error.email_error}
								/>
								{state.error.email_error && (
									<InputError fontSize='12px/18px'>E-mail inválido</InputError>
								)}
							</InputWrapper>
							<InputWrapper width='calc(50% - 12px)' margin='14px 0 0 24px'>
								<InputLabel fontSize='14px/24px'>Cpf</InputLabel>
								<InputElement
									p='2px 10px'
									withAllBorder
									disabled
									id='new-assistant-cpf'
									value={state.cpf}
								/>
							</InputWrapper>
						</DisplayFlex>
						<DisplayFlex>
							<InputWrapper width='calc(50% - 12px)'>
								<InputLabel fontSize='14px/24px'>Data de Nascimento</InputLabel>
								<InputElement
									p='2px 10px'
									withAllBorder
									id='new-assistant-date'
									placeholder='dd/mm/aaaa'
									value={state.birthDay}
									onChange={handleDate}
									error={state.error.birthDay_error}
								/>
								{state.error.birthDay_error && (
									<InputError fontSize='12px/18px'>Data inválida</InputError>
								)}
							</InputWrapper>
							<InputWrapper width='calc(50% - 12px)' margin='14px 0 0 24px'>
								<InputLabel fontSize='14px/24px'>Telefone</InputLabel>
								<InputElement
									p='2px 10px'
									withAllBorder
									id='new-assistant-phone'
									placeholder='(00) 00000-0000'
									value={state.phone}
									// tslint:disable-next-line: jsx-no-lambda
									onChange={event =>
										handleChange(event, {
											name: 'phone',
											value: formatPhone(event.target.value)
										})
									}
									error={state.error.phone_error}
								/>
								{state.error.phone_error && (
									<InputError fontSize='12px/18px'>Telefone inválido</InputError>
								)}
							</InputWrapper>
							{/* <InputWrapper width={'calc(50% - 12px)'} margin={'14px 0 0 24px'}>
							<InputLabel fontSize='14px/24px'>Horário</InputLabel>
							<Select
								id='new-assistant-hour'
								options={normalizerHours(schedulingList)}
								placeholder='Escolha'
								value={formValues.hour}
								onChange={handleHour}
								maxMenuHeight={150}
							/>
							{errors.hour && <InputError fontSize='12px/18px'>Horário inválido</InputError>}
						</InputWrapper> */}
						</DisplayFlex>
						<ButtonReturn id='button-back-assistant' onClick={handleClose}>
							Voltar
						</ButtonReturn>
						<SubmitButton
							id='button-send-assistant'
							onClick={handleSubmit}
							disabled={state.disabled}
						>
							Enviar
						</SubmitButton>
					</FormWrapper>
				</ModalWrapper>
			</ShowModal>
		</>
	)
}

export default ProfileForm
